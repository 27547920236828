<template lang="pug">
    .main-wrapper.parceiro-salvar
        ProgressBar(v-if="waiting" mode="indeterminate")
        div(v-else)
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon="jam jam-chevrons-left" @click="$router.go(-1)")
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub /
                            <router-link :to="{ name: 'parceiro' }"> Parceiros </router-link> /
                            <b>{{  model.id ? 'Editar' : 'Adicionar' }}</b>

            form(@submit.prevent="handleSubmit()")
                Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } parceiro`")
                    .p-grid.p-fluid

                        .p-col-12.p-md-9
                            .p-grid.p-align-end

                                .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nr_cnpj.$error }")
                                    label.form-label CNPJ *:
                                    InputMask(mask='99.999.999/9999-99' v-model='$v.model.nr_cnpj.$model')
                                    .feedback--errors(v-if='submitted && $v.model.nr_cnpj.$error')
                                        .form-message--error(v-if="!$v.model.nr_cnpj.minLength") Deve ter pelo menos 2 caracteres.
                                        .form-message--error(v-if="!$v.model.nr_cnpj.required") Campo obrigatório.

                                .p-col-12.p-md-5(:class="{ 'form-group--error': submitted && $v.model.nm_razao_social.$error }")
                                    label.form-label Razão social *:
                                    InputText(type='text' v-model='$v.model.nm_razao_social.$model')
                                    .feedback--errors(v-if='submitted && $v.model.nm_razao_social.$error')
                                        .form-message--error(v-if="!$v.model.nm_razao_social.minLength") Deve ter pelo menos 2 caracteres.
                                        .form-message--error(v-if="!$v.model.nm_razao_social.required") Campo obrigatório.

                                .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.nm_fantasia.$error }")
                                    label.form-label Nome fantasia *:
                                    InputText(type='text' v-model='$v.model.nm_fantasia.$model')
                                    .feedback--errors(v-if='submitted && $v.model.nm_fantasia.$error')
                                        .form-message--error(v-if="!$v.model.nm_fantasia.minLength") Deve ter pelo menos 2 caracteres.
                                        .form-message--error(v-if="!$v.model.nm_fantasia.required") Campo obrigatório.

                                .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nr_telefone.$error }")
                                    label.form-label Telefone *:
                                    InputMask(:mask="'(99) 99999999?9'" v-model='$v.model.nr_telefone.$model')
                                    .feedback--errors(v-if='submitted && $v.model.nr_telefone.$error')
                                        .form-message--error(v-if="!$v.model.nr_telefone.minLength") Deve ter pelo menos 2 caracteres.
                                        .form-message--error(v-if="!$v.model.nr_telefone.required") Campo obrigatório.

                                .p-col-12.p-md-9(:class="{ 'form-group--error': submitted && $v.model.ds_email.$error }")
                                    label.form-label Email *:
                                    InputText(type='text' v-model='$v.model.ds_email.$model')
                                    .feedback--errors(v-if='submitted && $v.model.ds_email.$error')
                                        .form-message--error(v-if="!$v.model.ds_email.minLength") Deve ter pelo menos 2 caracteres.
                                        .form-message--error(v-if="!$v.model.ds_email.required") Campo obrigatório.

                                .p-col-12
                                    label.form-label Mensagem label:
                                    InputText(type='text' v-model='model.ds_msg_label')

                        .p-col-12.p-md-3
                            .p-grid.p-fluid.p-justify-center

                                .p-col-10
                                    label.form-label Foto:
                                    .image-wrapper
                                        img(:src='model.path_foto')

                                .p-col-10
                                    FileUpload(
                                        name='aq_foto'
                                        chooseLabel='Escolher'
                                        mode='basic'
                                        accept="image/*"
                                        :auto='true'
                                        @before-send='beforeSendImage'
                                    )

                        .p-col-12.mt-2.mb-2
                            .p-grid
                                .p-col-12(:class="{ 'form-group--error': submitted && $v.model.ds_informacoes.$error }")
                                    label.form-label Informações *:
                                    Textarea(type='text' v-model='$v.model.ds_informacoes.$model' :autoResize="true" rows="3")
                                    .feedback--errors(v-if='submitted && $v.model.ds_informacoes.$error')
                                        .form-message--error(v-if="!$v.model.ds_informacoes.minLength") Deve ter pelo menos 2 caracteres.
                                        .form-message--error(v-if="!$v.model.ds_informacoes.required") Campo obrigatório.

                                .p-col-12
                                    label.form-label Mais informações:
                                    Textarea(type='text' v-model='model.ds_mais_informacoes' :autoResize="false" rows="5")

                                .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.cd_estado.$error }")
                                    label.form-label Estado *:
                                    Dropdown(v-model='$v.model.cd_estado.$model' :options='options.ufs' @change='getCidades()'
                                        optionLabel='textAlt' optionValue='value' placeholder='Selecione')
                                    .feedback--errors(v-if='submitted && $v.model.cd_estado.$error')
                                        .form-message--error(v-if="!$v.model.cd_estado.required") <b>Estado</b> é obrigatório.

                                .p-col-12.p-md-2(v-if="$v.model.cd_estado.$model" :class="{ 'form-group--error': submitted && $v.model.cd_cidade.$error }")
                                    label.form-label Município *:
                                    Dropdown(v-model='$v.model.cd_cidade.$model' :options='options.cidades'
                                        optionLabel='text' optionValue='value' placeholder='Selecione')
                                    .feedback--errors(v-if='submitted && $v.model.cd_cidade.$error')
                                        .form-message--error(v-if="!$v.model.cd_cidade.required") <b>Município</b> é obrigatório.

                                .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nm_rua.$error }")
                                    label.form-label Rua *:
                                    InputText(type='text' v-model='$v.model.nm_rua.$model')
                                    .feedback--errors(v-if='submitted && $v.model.nm_rua.$error')
                                        .form-message--error(v-if="!$v.model.nm_rua.minLength") Deve ter pelo menos 2 caracteres.
                                        .form-message--error(v-if="!$v.model.nm_rua.required") Campo obrigatório.

                                .p-col-12.p-md-1(:class="{ 'form-group--error': submitted && $v.model.nr_numero.$error }")
                                    label.form-label Número *:
                                    InputText(type='text' v-model='$v.model.nr_numero.$model')
                                    .feedback--errors(v-if='submitted && $v.model.nr_numero.$error')
                                        .form-message--error(v-if="!$v.model.nr_numero.minLength") Deve ter pelo menos 2 caracteres.
                                        .form-message--error(v-if="!$v.model.nr_numero.required") Campo obrigatório.

                                .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.nm_bairro.$error }")
                                    label.form-label Bairro *:
                                    InputText(type='text' v-model='$v.model.nm_bairro.$model')
                                    .feedback--errors(v-if='submitted && $v.model.nm_bairro.$error')
                                        .form-message--error(v-if="!$v.model.nm_bairro.minLength") Deve ter pelo menos 2 caracteres.
                                        .form-message--error(v-if="!$v.model.nm_bairro.required") Campo obrigatório.

                                .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.nr_cep.$error }")
                                    label.form-label CEP *:
                                    InputMask(mask='99999-999' v-model='$v.model.nr_cep.$model')
                                    .feedback--errors(v-if='submitted && $v.model.nr_cep.$error')
                                        .form-message--error(v-if="!$v.model.nr_cep.minLength") Deve ter pelo menos 2 caracteres.
                                        .form-message--error(v-if="!$v.model.nr_cep.required") Campo obrigatório.

                        .p-col-12.mt-2.mb-2(v-if="model.id")
                            .p-grid
                                .p-col-12.p-md-6.p-lg-3
                                    span.d-block.o-hidden <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                                .p-col-12.p-md-6.p-lg-3
                                    span <b>Data da criação: </b> {{ model.dt_criado_f }}
                                .p-col-12.p-md-6.p-lg-3
                                    span.d-block.o-hidden <b>Usuário edição: </b> {{ model.nm_usuario_edi }}
                                .p-col-12.p-md-6.p-lg-3
                                    span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}

                        .p-col-12.ta-center
                            .p-field-checkbox.mr-4(style="display:inline-flex; margin:0")
                                label.form-label Habilitado:
                                InputSwitch.ml-1(v-model='model.ie_habilitado')
                            ProgressSpinner.progress-small(v-if="waitingForm" strokeWidth="6")
                            Button(v-else label='Salvar' type="submit" style='max-width:160px')
</template>

<style lang="scss">
    .parceiro-salvar {
        .image-wrapper {
            font-size: 0;
            text-align: center;
            margin-top: 10px;
            overflow: hidden;
            img {
                width: 100%;
            }
        }
        .progress-small {
            width: 29px;
            height: auto;
        }
    }
</style>

<script>
    import ProgressBar from "primevue/progressbar"
    import Panel from "primevue/panel"
    import Dropdown from 'primevue/dropdown'
    import FileUpload from "primevue/fileupload"
    import InputMask from "primevue/inputmask"
    import InputSwitch from "primevue/inputswitch"
    import InputText from "primevue/inputtext"
    import Button from "primevue/button"
    import ProgressSpinner from "primevue/progressspinner"
    import Textarea from "primevue/textarea"

    import Parceiro from "@/middleware/controllers/Parceiro"
    import {minLength, required} from "vuelidate/lib/validators"
    import moment from "moment"
    import {Utils} from "@/middleware"

    export default {
        components: { ProgressBar, Panel, Dropdown, FileUpload, InputSwitch,
            InputMask, InputText, Button, ProgressSpinner, Textarea },
        created() {
            this.waiting = true
            Utils.getUFs().then(response => {
                this.waiting = false
                this.options.ufs = [{ value: null, text: '- Selecione -', textAlt: '- Selecione -' }]
                if (response.status === 200) response.data.forEach(uf => {
                    this.options.ufs.push({ value: uf.id, text: uf.ds_sigla, textAlt: uf.nm_estado })
                })

                const id = parseInt(this.$route.params.id)
                if (! isNaN(id) && id !== 0) this.getModel(id)
            })
        },
        data() {
            return {
                waiting: false,
                waitingForm: false,
                submitted: false,
                model: {
                    aq_foto: null,
                    nm_bairro: '',
                    nm_fantasia: '',
                    nm_razao_social: '',
                    nm_rua: '',
                    ds_email: '',
                    ds_informacoes: '',
                    ds_mais_informacoes: '',
                    ds_msg_label: '',
                    ie_habilitado: true,
                    cd_cidade: null,
                    cd_estado: null,
                    nr_cep: null,
                    nr_cnpj: null,
                    nr_numero: null,
                    nr_telefone: null,
                    path_foto: '',
                },
                options: {
                    ufs: [],
                    cidades: []
                }
            }
        },
        validations () {
            let v = {
                model: {
                    ds_email: {required,  minLength: minLength(2) },
                    ds_informacoes: { required, minLength: minLength(2) },
                    nm_bairro: {minLength: minLength(2) },
                    nm_fantasia: { required, minLength: minLength(2) },
                    nm_razao_social: { required, minLength: minLength(2) },
                    nm_rua: { minLength: minLength(2) },
                    nr_cep: { minLength: minLength(2) },
                    nr_cnpj: { required, minLength: minLength(2) },
                    nr_numero: { minLength: minLength(2) },
                    nr_telefone: { minLength: minLength(2) },
                    cd_cidade: {},
                    cd_estado: {},
                }
            }
            return v
        },
        methods: {
            beforeSendImage(ev) {
                ev.xhr.open('GET', '/')

                if (! ev.formData.get('aq_foto').type.includes("image/")) {
                    this.$toast.error("Tipo do arquivo é inválido! Precisa ser imagem.")
                    return
                }

                if (! ev.formData.get('aq_foto').size > 2097152) {
                    this.$toast.error("Tamanho do arquivo é inválido! Tamanho máximo: 2mb.")
                    return
                }

                this.model.aq_foto = ev.formData.get('aq_foto')
                this.model.path_foto = URL.createObjectURL(this.model.aq_foto)
            },
            loadModelParams(data) {
                Object.keys(this.model).forEach(key => this.model[key] = data[key])
                this.model.id = data.id
                this.model.path_foto = data.aq_foto
                this.model.aq_foto = null
                this.model.nm_usuario_cri = data.nm_usuario_cri
                this.model.dt_criado_f = moment(data.dt_criado).format('DD/MM/YYYY HH:mm')
                if (data.dt_atualizado) {
                    this.model.nm_usuario_edi = data.nm_usuario_edi
                    this.model.dt_atualizado_f = moment(data.dt_atualizado).format('DD/MM/YYYY HH:mm')
                }
                if (data.cd_estado) {
                    this.getCidades()
                    this.model.cd_cidade = data.cd_cidade
                }
            },
            getModel(id) {
                this.waiting = true
                Parceiro.find(id).then(response => {
                    this.loadModelParams(response.data)
                    this.waiting = false
                })
            },
            handleSubmit() {
                this.submitted = true

                this.$v.$touch()
                if (this.$v.$invalid) return 0

                let dataSend = Object.assign({}, this.model)
                if (! dataSend.aq_foto) delete dataSend.aq_foto

                this.waitingForm = true
                Parceiro.save(dataSend).then(response => {
                    this.waitingForm = false
                    if ([200,201].includes(response.status)) {
                        this.$toast.success("Cadastro salvo com sucesso.")
                        response.data.cd_estado = this.model.cd_estado
                        this.loadModelParams(response.data)
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            },
            getCidades() {
                this.model.cd_cidade = null
                if(this.model.cd_estado !== null) {
                    Utils.getMunicipiosEstadoId(this.model.cd_estado).then(response => {
                        this.options.cidades = [{ value: null, text: '- Selecione -' }]
                        if (response.status === 200) response.data.forEach(cidade => {
                            this.options.cidades.push({ value: cidade.id, text: cidade.nm_cidade })
                    })
                })
                }
               
            }
        }
    }
</script>